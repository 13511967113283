::-webkit-scrollbar {
  width: 0.5em;
}
 
::-webkit-scrollbar-track {
  background-color: #001111;
  background-color: rgba(172, 0, 0, 0.3);
  border-radius: 10px;
}
 
::-webkit-scrollbar-thumb {
  background-color: #AC0000;
  border-radius: 10px;
}

.bg-dark {
  background-color: #001111 !important;
}
.bg-dark-transparent {
  background-color: rgba(0, 0, 0, 0.8);
}
.bg-primary {
  background-color: #AC0000 !important;
}
.bg-secondary {
  background-color: #880201 !important;
}
.bg-tertiary {
  background-color: #DDDDDD !important;
}
.bg-orange {
  background-color: orange !important;
}

.border-primary {
  border-color: #AC0000 !important;
}
.border-dark {
  border-color: #000000 !important;
}

.btn.btn-link, .btn.btn-link:active, .btn.btn-link:focus {
  color: #AC0000 !important;
}
.btn.btn-link:hover {
  color: #ffffff !important;
}

.text-primary {
  color: #AC0000 !important;
}
.text-dark {
  color: #000000 !important;
}
.text-orange {
  color: orange !important;
}

.btn-primary {
  background-color: #AC0000;
  border-color: #AC0000;
  color: #ffffff;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  background-color: #000000;
  border-color: #AC0000;
  color: #AC0000;
}

.btn-outline-primary {
  background-color: #ffffff;
  border-color: #AC0000;
  color: #AC0000;
}
.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active {
  background-color: #ffffff;
  border-color: #AC0000;
  color: #AC0000;
}

.form-control:read-only {
  background-color: #cccccc !important;
}
.form-select:disabled {
  background-color: #cccccc !important;
}

small {
  font-size: .75rem;
}